import keyBy from 'lodash/keyBy';

export default {
  methods: {
    async attachProperties(records = []) {
      const ids = records.map((record) => record.propertyId);
      const { list } = await this.$store.dispatch('properties/names', { id: ids, infinite: true });
      const group = keyBy(list, 'newPropertyCode');
      const data = [];

      for (let i = 0; i < records.length; i += 1) {
        const record = records[i];

        data.push({
          ...record,
          property: group[record.propertyId] || {},
        });
      }

      return data;
    },
  },
};
