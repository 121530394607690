<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-2">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        style="min-width: 150px;"
        @change="handleFilter"
      />
    </div>
    <div class="column is-2">
      <a-input
        v-model:value="name"
        :size="size"
        :placeholder="$t('Search Item name')"
        @change="handleFilter"
      />
    </div>
    <div v-show="propertyId || name" class="column">
      <a-button :size="size" style="min-width: 110px" type="default" @click="handleClear">
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "date range": "date range",
    "Search": "Search",
    "Clear": "Clear",
    "Request Date" : "Request Date",
  },
  "ja": {
    "date range": "日付範囲",
    "Search": "サーチ",
    "Clear": "クリア",
    "Request Date" : "リクエスト日",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';

export default {
  name: 'RoomBottomFilter',
  components: {
    InputProperty,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter', 'clear-filter'],
  data() {
    return {
      propertyId: undefined,
      name: undefined,
    };
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.name = this.$route.query.name || undefined;
  },
  methods: {
    handleFilter: debounce(async function handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        name: this.name,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 300),
    async handleClear() {
      this.propertyId = undefined;
      this.name = undefined;

      await this.$router.push({ query: {} });
      this.handleFilter();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
