<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h2>
      </div>
    </div>

    <a-form-item class="column is-12" :label="$t('Item Name')" name="name">
      <a-input v-model:value="form.name" :size="size" style="width: 100%;" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" @change="validField('propertyId')" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom
        v-model:value="form.roomId"
        :property-id="form.propertyId"
        :disabled="!form.propertyId"
        @change="validField('roomId')"
      />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Cost')" name="cost">
      <a-input-number v-model:value="form.cost" :size="size" style="width: 100%;" />
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('Cost Unit')"
      name="unit"
    >
      <a-select
        v-model:value="form.unit"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="unit in unitOpts" :key="unit">
          {{ $t(unit) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Covers')" name="covers">
      <a-select
        v-model:value="form.covers"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="cover in coverOpts" :key="cover">
          {{ $t(cover) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <div class="is-flex is-justify-content-space-between">
        <div style="min-width: 100px">
          <div v-if="value && value.id">
            <DeleteRoomBottom :record="value" @delete="$emit('close')">
              <template #handler="{ show }">
                <a-button
                  :size="size"
                  type="danger"
                  @click="show"
                >
                  {{ $t('Delete') }}
                </a-button>
              </template>
            </DeleteRoomBottom>
          </div>
        </div>
        <div>
          <a-button
            :disabled="loading"
            :size="size"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px"
            @click="resetFields"
          >
            {{ $t('Cancel') }}
          </a-button>
          <a-button
            :loading="isSubmitting"
            :size="size"
            class="m-r-3x"
            style="min-width: 100px"
            type="primary"
            @click="handleSubmit"
          >
            {{ $t('Save') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add Room Bottom": "Add Room Bottom",
    "Edit Room Bottom": "Edit Room Bottom",
    "Item Name": "Item Name",
    "Cost": "Cost",
    "Cost Unit": "Cost Unit",
    "Covers": "Covers",
    "Item name is required": "Item name is required",
    "Property is required": "Property is required",
    "Room is required": "Room is required",
    "Cost is required": "Cost is required",
    "Cost unit is required": "Cost unit is required",
    "Covers is required": "Covers is required",
    "price": "Price",
    "percentage": "Percentage",
    "per-day": "Per Day",
    "per-max-pax": "Per Max Pax"
  },
  "ja": {
    "Add Room Bottom": "ボトム料金を追加する",
    "Edit Room Bottom": "ボトム料金を編集する",
    "Item Name": "ボトム名",
    "Cost": "数値",
    "Cost Unit": "単位",
    "Covers": "条件",
    "Item name is required.": "ボトム名は必須です",
    "Property is required.": "物件は必須です",
    "Room is required.": "部屋必須はです",
    "Cost is required.": "金額必須です",
    "Cost unit is required.": "単位は必須項目です",
    "Covers is required.": "条件は必須です",
    "price": "価格",
    "percentage": "%",
    "per-day": "日ごと",
    "per-max-pax": "最大人数ごと",
  }
}
</i18n>

<script>
import InputRoom from '@/components/InputRoom';
import InputProperty from '@/components/InputProperty';
import { COST_UNITS, COVERS } from '@/config/revenue-managements';
import DeleteRoomBottom from '@/views/revenue-management/room-bottoms/components/DeleteRoomBottom';

export default {
  name: 'RoomBottomForm',
  components: {
    InputProperty,
    InputRoom,
    DeleteRoomBottom,
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      form: {
        name: undefined,
        propertyId: undefined,
        roomId: undefined,
        cost: undefined,
        unit: undefined,
        covers: undefined,
      },
      unitOpts: COST_UNITS,
      coverOpts: COVERS,
      size: 'large',
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit Room Bottom')
        : this.$t('Add Room Bottom');
    },
    rules() {
      const rules = {
        name: [
          {
            required: true,
            message: this.$t('Item name is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        roomId: [
          {
            required: true,
            type: 'number',
            message: this.$t('Room is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        cost: [
          {
            required: true,
            type: 'number',
            message: this.$t('Cost is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        unit: [
          {
            required: true,
            message: this.$t('Cost unit is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        covers: [
          {
            required: true,
            message: this.$t('Covers is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isSubmitting = true;
        await this.$refs.form.validate();

        if (this.value && this.value.id) {
          await this.$store.dispatch('rms-room-bottoms/update', {
            ...this.form,
            id: this.value.id,
          });
        } else {
          await this.$store.dispatch('rms-room-bottoms/create', this.form);
        }

        this.isSubmitting = false;
        this.$emit('close');
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    validField(f) {
      setTimeout(() => {
        this.$refs.form.validateFields([f]);
      }, 300);
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
