<template>
  <Delete
    action-handler="rms-room-bottoms/deleteRecord"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.id })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.id })"
    @close="$emit('delete')"
  >
    <template #handler="{ show }">
      <slot name="handler" :show="show" />
    </template>
  </Delete>
</template>

<i18n>
{
  "en": {
    "title": "Delete Room Bottom?",
    "desc": "Are you sure you want to delete room bottom <strong>{name}</strong> ?",
    "deleted-title": "Room bottom deleted",
    "deleted-desc": "Room bottom <strong>{name}</strong> has been deleted."
  },
  "ja": {
    "title": "ボトム削除の確認",
    "desc": "本当に <strong>{name}</strong> を削除してもよろしいですか？",
    "deleted-title": "削除完了",
    "deleted-desc": "ボトム料金が削除されました"
  }
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteRoomBottom',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
